<template>
  <v-container class="px-6">
    <div class=" mb-5 container text-center">
      <img src="/Aurora-mini.svg" style="margin: 5% 0px 3% 0px" height="44" />
    </div>
  </v-container>
</template>

<script>

const axios = require("axios");

import * as urls from "../../urls";

import IconLoader from '../icons/IconLoader.vue';



export default {
  data() {
    return {
      formStatus: false,
      feedbackCheck: 0,
      colorCheck: 0,
      feedbackText: '',
      feedbackError: false,
      success: false,
      requestError: false,
      errorMessage: '',
    }
  },
  components: {
    IconLoader
  },
  watch: {
    feedbackCheck() {
      if (this.feedbackCheck === 1) {
        this.colorCheck = 1
      } else this.colorCheck = 2
    },
    feedbackText() {
      if (this.feedbackText !== '') {
        this.feedbackError = false
      }
    },
    success() {
      if (this.success === true) {
        setTimeout(() => {
          this.success = false
        }, "3000")
      }
    },
    requestError() {
      if (this.requestError === true) {
        setTimeout(() => {
          this.requestError = false
        }, "10000")
      }
    }
  },
  methods: {
    startForm(type) {
      this.formStatus = true;
      if (type === 1) {
        this.feedbackCheck = 1
      } else this.feedbackCheck = 2
    },
    sendEmail() {
      if (this.feedbackText === '') {
        this.feedbackError = true
        return
      } else {

        let emailSubject = '';

        const pageUrl = window.location.href;

        if (this.feedbackCheck === 1) {
          emailSubject = 'How did this page help you?'
        } else emailSubject = 'How can we improve this page?'

        axios
          .request({
            method: 'POST',
            data: {
              emailSubject: emailSubject,
              emailBody: this.feedbackText,
              pageUrl: pageUrl
            },
            url: `${urls.EMPLOYEES_URL}feebackForm`
          }
          )
          .then((resp) => {
            console.log(resp)
            if (resp) {
              this.formStatus = false, this.success = true, this.colorCheck = 0, this.feedbackText = ''
            }
          })
          .catch((err) => {
            this.requestError = true;
            this.errorMessage = err
            console.error(err)
          });
      }
    }
  },
}

</script>

<style>
.btn {
  color: white !important;
  border: none !important;
}

.area {
  border: #ccc !important;
}

.icon {
  cursor: pointer;
}

.help {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.form-success {
  border-left: #278400 5px solid !important;
  background-color: #dbedcf !important;
}

.feedback-form {
  border-bottom: none !important;
  border-right: none !important;
  border-top: none !important;
  border-radius: 0 !important;
  font-size: 16px !important;
}

.form-error {
  border-left: #a94442 5px solid !important;
  background-color: #f3e9e8 !important;
}

.form-error p {
  color: #a94442 !important;
}
</style>