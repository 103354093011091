import { render, staticRenderFns } from "./SearchBarHeader.vue?vue&type=template&id=23ecb177&scoped=true&"
import script from "./SearchBarHeader.vue?vue&type=script&lang=js&"
export * from "./SearchBarHeader.vue?vue&type=script&lang=js&"
import style0 from "./SearchBarHeader.vue?vue&type=style&index=0&id=23ecb177&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23ecb177",
  null
  
)

export default component.exports